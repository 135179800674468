<template>
  <div class="label-content">
    <div class="label-text leading-none" :class="labelClass">
      <slot name="label">
        <span class="label-text whitespace-pre-wrap">{{ label }}</span>

        <template v-if="tooltip || $slots.content">
          <span class="pad-left-xxs">
            <el-tooltip
              effect="light"
              placement="top"
              :content="$slots.content ? null : tooltip"
            >
              <template #content>
                <slot name="content" />
              </template>

              <icon :icon="infoCircled" class="text-dark-silver" />
            </el-tooltip>
          </span>
        </template>

        <template v-if="disabled">
          <span class="pad-left-xxs">
            <el-tooltip effect="light" placement="top">
              <template #content>
                <slot name="disabled">
                  <div class="w-40">
                    <a
                      href="https://intercom.help/flytedesk/articles/3102562-assigning-team-roles"
                      target="_blank"
                      >Learn more about user permission roles
                    </a>
                  </div>
                </slot>
              </template>
              <icon :icon="lock" class="text-dark-silver" />
            </el-tooltip>
          </span>
        </template>
      </slot>
    </div>

    <div
      v-if="$slots.help || help"
      class="help text-dark-silver text-md pad-top-xs"
    >
      <slot name="help">{{ help }}</slot>
    </div>
  </div>
</template>

<script>
import { infoCircled, lock } from '@/vendor/icons';

export default {
  props: {
    label: { type: String, default: null },
    tooltip: { type: String, default: null },
    help: { type: String, default: null },
    labelClass: { type: String, default: null },
    disabled: Boolean
  },

  data() {
    return {
      // Icons
      infoCircled,
      lock
    };
  }
};
</script>
