<template>
  <label
    :is="nodeName"
    :id="inputId"
    class="el-form-item custom-el-form-item text-md"
    :class="formItemClass"
    @click="$emit('click')"
  >
    <template v-if="type === 'columns'">
      <div class="flex" :class="{ 'items-center': middle }">
        <div v-if="!hideLabel" class="block p-3 w-1/3">
          <label-content v-bind="$props">
            <template slot="disabled">
              <slot name="disabled" />
            </template>
            <template slot="label">
              <slot name="label" />
            </template>
            <template slot="content">
              <slot name="content" />
            </template>
            <template slot="help">
              <slot name="help" />
            </template>
          </label-content>
        </div>

        <div class="block py-3 pr-3 flex-1 w-2/3" :class="hideLabel || 'pl-12'">
          <div ref="value" class="value-content relative">
            <slot />

            <transition name="el-zoom-in-top">
              <div
                v-if="hasError"
                class="el-form-item__error"
                :class="formErrorClass"
              >
                {{ validateMessage }}
              </div>
            </transition>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <label-content v-if="!hideLabel" v-bind="$props">
        <template slot="disabled">
          <slot name="disabled" />
        </template>
        <template slot="label">
          <slot name="label" />
        </template>
        <template slot="content">
          <slot name="content" />
        </template>
        <template slot="help">
          <slot name="help" />
        </template>
      </label-content>

      <div ref="value" class="value-content pad-top-xs">
        <slot />
      </div>

      <transition name="el-zoom-in-top">
        <div
          v-if="hasError"
          class="el-form-item__error"
          :class="formErrorClass"
        >
          {{ validateMessage }}
        </div>
      </transition>
    </template>
  </label>
</template>

<script>
import { uniqueId } from 'lodash';
import { findChildNode } from '@/utils/helpers';
import { FormItem } from 'element-ui';

import LabelContent from '../Core/LabelContent';

export default {
  components: {
    LabelContent
  },

  mixins: [FormItem],
  props: {
    hideLabel: Boolean,
    disabled: Boolean,
    id: { type: String, default: null },

    // Layout Type ('columns', 'inline', 'stacked')
    type: { type: String, default: null },

    // The text for the label
    label: { type: String, default: null },

    // Info Icon tooltip
    tooltip: { type: String, default: null },

    // Helper copy to display below label text
    help: { type: String, default: null },

    // Vertical Alignment
    middle: Boolean,

    // Style
    labelClass: { type: String, default: null },

    // Focus Input when clicking label
    noClickFocus: Boolean,

    // Change parent node
    nodeType: { type: String, default: null }
  },

  data() {
    return {
      inputId: this.id || uniqueId('label-value-')
    };
  },

  computed: {
    hasError() {
      return (
        this.validateState === 'error' &&
        this.showMessage &&
        this.form.showMessage
      );
    },

    formErrorClass() {
      let isInline = this.inlineMessage;

      if (typeof isInline !== 'boolean') {
        isInline = (this.elForm && this.elForm.inlineMessage) || false;
      }

      return {
        'el-form-item__error--inline': isInline
      };
    },

    nodeName() {
      if (this.nodeType) {
        return this.nodeType;
      }

      return this.noClickFocus ? 'div' : 'label';
    },

    formItemClass() {
      return [
        {
          'is-vcenter': this.middle,
          'is-inline-form-item': this.type === 'inline',
          'el-form-item--feedback': this.elForm && this.elForm.statusIcon,
          'is-error': this.validateState === 'error',
          'is-validating': this.validateState === 'validating',
          'is-success': this.validateState === 'success',
          'is-required': this.isRequired || this.required,
          'cursor-not-allowed': this.disabled
        },
        this.sizeClass ? 'el-form-item--' + this.sizeClass : ''
      ];
    }
  },
  mounted() {
    // Find the associated form element and set the matching ID for the label
    let childInput = findChildNode(
      this.$refs.value,
      /(input|textarea|select)/i
    );

    if (childInput) {
      childInput.id = this.inputId;
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-el-form-item {
  display: block;
  position: relative;

  &.is-inline-form-item {
    .label-content,
    .value-content {
      display: inline-block;
    }

    .value-content {
      padding: 0 0 0 1em;
    }
  }

  &.is-vcenter {
    .label-content,
    .value-content {
      vertical-align: middle;
    }
  }
}
</style>
