<template>
  <div class="digital-analytics-empty-state flex justify-center">
    <div class="max-w-lg">
      <div v-if="scriptTagStatus.is_installed">
        <div class="text-lg font-semibold">
          No ad unit reporting data found...
        </div>
        <div class="mt-5">
          You have successfully installed your Flytedesk Digital script tag but
          no ad units have been detected.
        </div>
        <div class="mt-1">
          Please follow <a href="https://flytedesk.com">the guide here</a> to
          learn about completing the installation process
        </div>
      </div>
      <div v-else>
        <div class="text-lg font-semibold">Please Install Script Tag...</div>
        <div class="mt-2">
          To use Digital Analytics, you must install Flytedesk Digital script
          tag and ad units, which allow our platform to automatically serve both
          flytedesk National and Ad Shop ads on your site.
          <a
            href="https://intercom.help/flytedesk/en/collections/1234909-flytedesk-digital"
            >Learn more</a
          >
        </div>
        <el-form v-if="property">
          <digital-script-tag-settings :property="property" hide-label />
        </el-form>
        <el-alert v-else type="error" title="" :closable="false" class="mt-5">
          Unable to locate a digital property for this Supplier. Please contact
          us for help.
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>
import DigitalScriptTagSettings from '@/components/Supplier/Mediakit/Common/DigitalScriptTagSettings';

export default {
  components: {
    DigitalScriptTagSettings
  },
  props: {
    property: {
      type: Object,
      default: null
    },
    digitalStatus: {
      type: Object,
      required: true
    }
  },
  computed: {
    scriptTagStatus() {
      return (this.digitalStatus && this.digitalStatus.scriptTag) || {};
    }
  }
};
</script>
