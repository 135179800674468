import { render, staticRenderFns } from "./CustomElFormItem.vue?vue&type=template&id=936ef554&scoped=true&"
import script from "./CustomElFormItem.vue?vue&type=script&lang=js&"
export * from "./CustomElFormItem.vue?vue&type=script&lang=js&"
import style0 from "./CustomElFormItem.vue?vue&type=style&index=0&id=936ef554&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "936ef554",
  null
  
)

export default component.exports