<template>
  <dashboard-container class="digital-analytics-report-stats-dashboard">
    <template #title>
      <div class="flex">
        <div class="flex flex-grow items-center">
          <transition name="fade" mode="out-in">
            <div :key="'stats-title-' + statsTab">{{ statsTabTitle }}</div>
          </transition>
          <div v-if="filterDateRange" class="ml-1">
            between {{ filterDateRange.startDate | date }} and
            {{ filterDateRange.endDate | date }}
          </div>
          <info-tooltip class="pl-1">
            <template>
              <div>
                The {{ statsTabTitle | lowercase }} across all properties. If
                filters are applied, these will show the data applicable to the
                filter.
              </div>
            </template>
          </info-tooltip>
        </div>
        <div class="flex">
          <a
            :class="{
              'font-bold text-dark-silver': statsTab === 'totals'
            }"
            @click="statsTab = 'totals'"
          >
            Totals
          </a>
          <a
            class="border-left ml-2 pl-2"
            :class="{
              'font-bold text-dark-silver': statsTab === 'monthly'
            }"
            @click="statsTab = 'monthly'"
          >
            Monthly Avg.
          </a>
          <a
            class="border-left ml-2 pl-2"
            :class="{
              'font-bold text-dark-silver': statsTab === 'daily'
            }"
            @click="statsTab = 'daily'"
          >
            Daily Avg.
          </a>
        </div>
      </div>
    </template>

    <digital-analytics-stats
      :advanced-stats="advancedStats"
      :stats="reportStats[statsTab]"
    />
  </dashboard-container>
</template>

<script>
import DashboardContainer from '@/components/Supplier/Analytics/DashboardContainer';
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';
import DigitalAnalyticsStats from '@/components/Supplier/Analytics/Digital/DigitalAnalyticsStats';

export default {
  components: {
    DigitalAnalyticsStats,
    DashboardContainer,
    InfoTooltip
  },
  props: {
    advancedStats: Boolean,
    reportStats: {
      type: Object,
      default: null
    },
    filter: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      statsTab: 'totals'
    };
  },
  computed: {
    statsTabTitle() {
      switch (this.statsTab) {
        case 'totals':
          return 'Totals';

        case 'daily':
          return 'Daily Averages';

        case 'monthly':
          return 'Monthly Averages';
      }

      return 'Unknown tab';
    },
    filterDateRange() {
      let dateRange = this.filter && this.filter['dates.date'];

      if (dateRange) {
        return {
          startDate: dateRange['>='] || dateRange['='],
          endDate: dateRange['<='] || dateRange['=']
        };
      }

      return null;
    }
  }
};
</script>
