<template>
  <el-tooltip effect="light" v-bind="$props" :placement="placement">
    <div class="info-tooltip">
      <icon :icon="icon" />
    </div>
    <template #content>
      <div class="text-md">
        <slot>{{ content }}</slot>
      </div>
    </template>
  </el-tooltip>
</template>
<script>
import { infoCircled as infoIcon } from '@/vendor/icons';

import { Tooltip } from 'element-ui';

export default {
  props: {
    ...Tooltip.props,
    icon: {
      type: Object,
      default: () => infoIcon
    },
    placement: {
      type: String,
      default: 'bottom'
    }
  }
};
</script>
