<template>
  <span>
    Viewable Impressions (50%)
    <info-tooltip
      content="Creative for an ad appears on a user's screen and is more than 50% visible for at least 1 second."
    />
  </span>
</template>
<script>
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';

export default {
  components: { InfoTooltip }
};
</script>
