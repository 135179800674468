<script>
import { exportSupplierDigitalAdAnalytics } from '@/graphql/analytics/digital/supplierDigitalAdExport.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        exportSupplierDigitalAdAnalytics(filter, groupBy) {
          return this.post(exportSupplierDigitalAdAnalytics, {
            filter,
            groupBy
          });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
