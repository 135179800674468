<script>
import { getSupplierDigitalAdAnalytics } from '@/graphql/analytics/digital/supplierDigitalAnalyticsQueries.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    type: {
      type: String,
      required: true
    },
    pager: {
      type: Object,
      default: null
    }
  },
  query() {
    return {
      query: getSupplierDigitalAdAnalytics,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        type: this.type,
        ...(this.pager || {})
      }
    };
  }
};
</script>
