<template>
  <span>
    Clicks
    <info-tooltip content="The number of times an ad creative was clicked" />
  </span>
</template>
<script>
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';

export default {
  components: { InfoTooltip }
};
</script>
