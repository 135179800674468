<template>
  <span>
    Impressions
    <info-tooltip
      content="If creative for an ad is requested on a web page, it will count as an impression"
    />
  </span>
</template>
<script>
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';

export default {
  components: { InfoTooltip }
};
</script>
