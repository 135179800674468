<template>
  <div class="contact-info">
    <slot name="title">
      <h4 v-if="title" class="contact-title">{{ title }}:</h4>
    </slot>
    <div class="contact-name">{{ contactName }}</div>
    <div v-if="contact.email" class="contact-email">{{ contact.email }}</div>
    <div v-if="contactPhone" class="contact-phone">
      {{ contactPhone | phone }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      required: true
    },

    title: {
      type: String,
      default: null
    }
  },

  computed: {
    contactName() {
      if (this.contact.name) {
        return this.contact.name;
      }

      if (this.contact.first_name) {
        return (
          this.contact.first_name +
          (this.contact.last_name ? ' ' + this.contact.last_name : '')
        );
      } else {
        return '';
      }
    },
    contactPhone() {
      return this.contact.phone || this.contact.buyer_phone;
    }
  }
};
</script>
