<script>
import { getSupplierDigitalAdAnalyticsReport } from '@/graphql/analytics/digital/supplierDigitalAnalyticsQueries.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    type: {
      type: String,
      required: true
    },
    dimensions: {
      type: Array,
      required: true
    },
    metrics: {
      type: Array,
      required: true
    },
    comparisons: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      default: null
    },
    sort: {
      type: Array,
      default: null
    }
  },
  query() {
    return {
      query: getSupplierDigitalAdAnalyticsReport,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        type: this.type,
        input: {
          dimensions: this.dimensions,
          metrics: this.metrics,
          // No comparisons for now
          comparisons: this.comparisons,
          filter: this.filter,
          sort: this.sort
        }
      }
    };
  }
};
</script>
