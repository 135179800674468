<template>
  <el-tooltip
    :content="content"
    :placement="placement"
    :disabled="tooltipWhileEnabled ? false : !isDisabled"
    effect="light"
  >
    <template #content>
      <slot v-if="isDisabled" name="disabled">
        <div class="w-40">
          <a
            href="https://intercom.help/flytedesk/articles/3102562-assigning-team-roles"
            target="_blank"
            >Learn more about user permission roles
          </a>
        </div>
      </slot>
      <slot v-else name="enabled" />
    </template>
    <div
      :class="[referenceClass, disabledClass]"
      @click="!isDisabled && $emit('click')"
    >
      <slot name="before" />
      <icon
        :class="[iconClass, { 'cursor-not-allowed': isDisabled }]"
        :style="disabledStyle"
        :icon="displayIcon"
      />
      <slot name="after" />
    </div>
  </el-tooltip>
</template>

<script>
import { helpCircled as infoIcon, lock as lockIcon } from '@/vendor/icons';

export default {
  props: {
    content: {
      type: String,
      default: null
    },
    info: Boolean,
    isDisabled: Boolean,
    icon: {
      type: Object,
      default: null
    },
    iconClass: {
      type: String,
      default: 'icon-md'
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    referenceClass: {
      type: String,
      default: 'px-4 cursor-pointer'
    },
    tooltipWhileEnabled: Boolean
  },

  data() {
    return {
      infoIcon,
      lockIcon
    };
  },

  computed: {
    displayIcon() {
      if (this.icon) {
        return this.icon;
      }

      return this.info ? infoIcon : lockIcon;
    },

    disabledClass() {
      return this.isDisabled ? 'cursor-not-allowed opacity-50' : '';
    },
    disabledStyle() {
      return this.isDisabled && { animation: 'none' };
    }
  }
};
</script>
