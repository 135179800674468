<template>
  <span>
    Viewable CTR (50%)
    <info-tooltip
      content="50% Viewable click through rate is the number of clicks the ad received divided by the number of impressions that were 50% viewable for at least 1 second."
    />
  </span>
</template>
<script>
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';

export default {
  components: { InfoTooltip }
};
</script>
