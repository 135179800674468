<template>
  <section class="flex justify-between">
    <div class="flex flex-row justify-start space-x-4">
      <slot name="before" />
      <div class="max-w-full">
        <el-input
          v-model="searchText"
          :placeholder="placeholder"
          class="w-full"
          prefix-icon="el-icon-search"
          clearable
          @input="applySearchTerms"
        />
        <div class="text-gray text-md mt-2 ml-1">
          Tip: Use a comma (,) to separate search terms
        </div>
      </div>
    </div>

    <div class="flex">
      <slot />
      <export-button
        v-if="!hideExport"
        class="ml-4"
        :is-exporting="isExporting"
        @click="$emit('export')"
      />
    </div>
  </section>
</template>

<script>
import ExportButton from '@/components/Core/Table/ExportButton';
import { debounce } from 'lodash';

export default {
  components: {
    ExportButton
  },
  props: {
    hideExport: Boolean,
    isExporting: Boolean,
    searchTip: Boolean,
    placeholder: {
      type: String,
      default: 'Search'
    },
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      searchText: (this.value || {}).keywords
    };
  },
  methods: {
    applySearchTerms: debounce(function() {
      this.$emit('input', {
        ...this.value,
        keywords: this.searchText || null
      });
    }, 500)
  }
};
</script>
