<script>
import { getSupplierDigitalAnalyticsSearchTools } from '@/graphql/analytics/digital/supplierDigitalAnalyticsSearchToolsQueries.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    type: {
      type: String,
      required: true
    }
  },
  query() {
    return {
      query: getSupplierDigitalAnalyticsSearchTools,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        type: this.type
      }
    };
  }
};
</script>
