<template>
  <div class="digital-script-tag-settings">
    <custom-el-form-item
      type="columns"
      :hide-label="hideLabel"
      label="Digital Settings"
      class="margin-top-xl pad-bottom-xl"
      label-class="font-semibold"
      help="We need a little more information for digital properties."
      no-click-focus
    >
      <custom-el-form-item
        label="Script Tag"
        help="You only need to enter this tag once, in the header of your website."
        label-class="font-semibold"
        no-click-focus
      >
        <div class="margin-top-xs">
          <digital-script-tag :id="property.id" copy preview />
          <div v-if="$can('preview_digital_script_tag')" class="mt-5">
            <div class="font-semibold mb-1">Flytedesk Debug Console</div>
            <div class="bg-blue px-5 py-2 text-white">
              ?fddebug={"secretKey":"{{ property.aes_256_key }}"}
            </div>
          </div>
        </div>
      </custom-el-form-item>

      <custom-el-form-item
        type="columns"
        label="Property ID"
        label-class="font-semibold"
        class="mt-6"
      >
        <div class="text-center">
          <div class="text-dark-green text-lg font-semibold">
            Wordpress Users
          </div>
          <div>
            <a
              target="_blank"
              href="https://wordpress.org/plugins/flytedesk-digital/"
            >
              Install the Flytedesk Digital Plugin
            </a>
          </div>

          <div class="mt-5 font-bold">{{ property.id }}</div>

          <div class="mt-1 text-sm">
            (Copy and paste into the plugin settings)
          </div>
        </div>

        <div slot="help">
          Your Property ID is what you enter into your
          <a
            target="_blank"
            href="https://wordpress.org/plugins/flytedesk-digital/"
          >
            Flytedesk Digital Wordpress plugin
          </a>
        </div>
      </custom-el-form-item>

      <div v-if="value" class="flex -mb-3 -mx-3 mt-4">
        <div class="block p-3 flex-1">
          <custom-el-form-item
            label="Minimum Impressions"
            label-class="font-semibold"
            help="Minimum impressions a buyer can book at your CPM rate."
          >
            <el-input
              v-mask.range="{ min: 1000 }"
              :value="value.min_impressions"
              @input="$emit('input', { ...value, min_impressions: $event })"
              @change="$emit('change', $event)"
            />
          </custom-el-form-item>
        </div>

        <div class="block p-3 flex-1">
          <custom-el-form-item
            label="Minimum Days"
            label-class="font-semibold"
            help="Minimum days a buyer can book at your CPD rate."
          >
            <el-input
              v-mask.range="{ min: 1 }"
              :value="value.min_days"
              @input="$emit('input', { ...value, min_days: $event })"
              @change="$emit('change', $event)"
            />
          </custom-el-form-item>
        </div>
      </div>
    </custom-el-form-item>
  </div>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import DigitalScriptTag from '@/components/Supplier/Mediakit/Common/DigitalScriptTag';

export default {
  components: {
    CustomElFormItem,
    DigitalScriptTag
  },
  props: {
    hideLabel: Boolean,
    // The editable property values
    value: {
      type: Object,
      default: null
    },
    medium: {
      type: Object,
      default: null
    },
    property: { type: Object, required: true }
  }
};
</script>
