<template>
  <div class="digital-analytics-stats flex items-start justify-left">
    <template v-if="stats">
      <summary-item class="ml-3" :amount="stats.requests || 0">
        <partial-impressions-tooltip />
      </summary-item>
      <template v-if="advancedStats">
        <summary-item class="ml-3" :amount="stats.viewable_impressions || 0">
          <viewable-impressions-tooltip />
        </summary-item>
        <summary-item class="ml-3" :amount="stats.full_impressions || 0">
          <full-impressions-tooltip />
        </summary-item>
      </template>
      <summary-item class="ml-3" :amount="stats.clicks || 0">
        <clicks-tooltip />
      </summary-item>
      <summary-item class="ml-3" :amount="stats.ctr || 0" percent>
        <partial-ctr-tooltip />
      </summary-item>
      <template v-if="advancedStats">
        <summary-item class="ml-3" :amount="stats.viewable_ctr || 0" percent>
          <viewable-ctr-tooltip />
        </summary-item>
        <summary-item class="ml-3" :amount="stats.full_ctr || 0" percent>
          <full-ctr-tooltip />
        </summary-item>
      </template>
    </template>
    <template v-else>
      <div class="text-dark-silver">
        There is no data for the applied filters. Please try changing your
        search terms.
      </div>
    </template>
  </div>
</template>

<script>
import ClicksTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/ClicksTooltip';
import FullCtrTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/FullCtrTooltip';
import FullImpressionsTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/FullImpressionsTooltip';
import PartialCtrTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/PartialCtrTooltip';
import PartialImpressionsTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/PartialImpressionsTooltip';
import ViewableCtrTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/ViewableCtrTooltip';
import ViewableImpressionsTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/ViewableImpressionsTooltip';
import SummaryItem from '@/components/Supplier/Billing/SummaryItem';

export default {
  components: {
    ClicksTooltip,
    FullCtrTooltip,
    ViewableCtrTooltip,
    PartialCtrTooltip,
    PartialImpressionsTooltip,
    FullImpressionsTooltip,
    ViewableImpressionsTooltip,
    SummaryItem
  },
  props: {
    advancedStats: Boolean,
    stats: {
      type: Object,
      default: null
    }
  }
};
</script>
