<template>
  <div class="digital-ad-analytics-table">
    <div class="flex pb-3 px-4">
      <div class="text-dark-silver transition-all">
        <template v-if="multipleSelection.length > 0">
          {{ selectCount }} ads selected
          <a class="text-gray hover:text-dark-silver" @click="clearSelection">
            clear
          </a>
        </template>
        <template v-else>Select ads to filter charts below</template>
      </div>
    </div>
    <el-table
      ref="table"
      border
      class="w-full el-table-slim"
      :data="isLoading ? [] : ads"
      @selection-change="handleSelectionChange"
      @select-all="onSelectAll"
      @row-click="onRowClick"
    >
      <table-empty-slot
        slot="empty"
        :is-loading="isLoading"
        :loading-rows="paginatorInfo.perPage"
        :has-data="!!ads"
        empty-message="No ads were found matching your filter. Try changing your search terms."
        error-message="There was a problem loading your data. Please try again later."
      />

      <el-table-column type="selection" width="55" />

      <el-table-column prop="ref" label="Ad ID" width="110">
        <template v-slot="{ row }">
          <el-tooltip
            content="This is a national ad from Flytedesk"
            effect="light"
            :disabled="row.campaign_type !== CampaignType.NATIONAL"
          >
            <div>
              <div>
                {{ row.ref }}
                <span
                  v-if="row.campaign_type === CampaignType.NATIONAL"
                  class="text-blue"
                >
                  <icon :icon="nationalIcon" />
                </span>
              </div>
              <div class="text-dark-silver text-xs">
                Order #{{ row.order_ref }}
              </div>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column prop="variant_name" label="Ad Unit" min-width="130">
        <template v-slot="{ row }">
          <div class="flex items-center">
            <el-tooltip
              content="This unit is currently only available to Flytedesk national clients."
              effect="light"
              :disabled="!row.is_super"
            >
              <div>
                <div>
                  {{ row.variant_name }}
                  <span v-if="row.is_super" class="text-blue">
                    <icon :icon="superIcon" />
                  </span>
                </div>
                <div class="text-dark-silver text-xs">{{ row.sku }}</div>
              </div>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="property_name" label="Property" min-width="100" />

      <el-table-column label="Run Date" align="left" width="110">
        <template v-slot="{ row }">
          <div>{{ row.start_date | date }}</div>
          <div>- {{ row.end_date | date }}</div>
        </template>
      </el-table-column>

      <el-table-column label="Customer" min-width="150">
        <template v-slot="{ row }">
          <customer-column :customer="row.customer" />
        </template>
      </el-table-column>

      <custom-el-table-column prop="requests" width="130" align="center">
        <template #header>
          <partial-impressions-tooltip />
        </template>
        <template v-slot="{ row }">
          {{ row.requests | number(0) }}
        </template>
      </custom-el-table-column>

      <template v-if="advancedStats">
        <custom-el-table-column
          prop="viewable_impressions"
          width="220"
          align="center"
        >
          <template #header>
            <viewable-impressions-tooltip />
          </template>
          <template v-slot="{ row }">
            {{ row.viewable_impressions | number(0) }}
          </template>
        </custom-el-table-column>

        <custom-el-table-column
          prop="full_impressions"
          width="230"
          align="center"
        >
          <template #header>
            <full-impressions-tooltip />
          </template>
          <template v-slot="{ row }">
            {{ row.full_impressions | number(0) }}
          </template>
        </custom-el-table-column>
      </template>

      <custom-el-table-column prop="clicks" align="center" width="100">
        <template #header>
          <clicks-tooltip />
        </template>
        <template v-slot="{ row }">
          {{ row.clicks | number(0) }}
        </template>
      </custom-el-table-column>

      <custom-el-table-column prop="ctr" width="120" align="center">
        <template #header>
          <partial-ctr-tooltip />
        </template>
        <template v-slot="{ row }">
          {{ row.ctr | percent(1) }}
        </template>
      </custom-el-table-column>

      <template v-if="advancedStats">
        <custom-el-table-column prop="viewable_ctr" width="170" align="center">
          <template #header>
            <viewable-ctr-tooltip />
          </template>
          <template v-slot="{ row }">
            {{ row.viewable_ctr | percent(1) }}
          </template>
        </custom-el-table-column>

        <custom-el-table-column prop="full_ctr" width="180" align="center">
          <template #header>
            <full-ctr-tooltip />
          </template>
          <template v-slot="{ row }">
            {{ row.full_ctr | percent(1) }}
          </template>
        </custom-el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import {
  flytedesk as nationalIcon,
  starFull as superIcon
} from '@/vendor/icons';
import { CampaignType } from '@/constants';

import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import CustomElTableColumn from '@/components/Custom/CustomElTableColumn';
import CustomerColumn from '@/components/Core/Table/Columns/CustomerColumn';
import ViewableImpressionsTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/ViewableImpressionsTooltip';
import FullImpressionsTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/FullImpressionsTooltip';
import ClicksTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/ClicksTooltip';
import PartialImpressionsTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/PartialImpressionsTooltip';
import PartialCtrTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/PartialCtrTooltip';
import ViewableCtrTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/ViewableCtrTooltip';
import FullCtrTooltip from '@/components/Supplier/Analytics/Digital/Tooltips/FullCtrTooltip';

export default {
  components: {
    FullCtrTooltip,
    ViewableCtrTooltip,
    PartialCtrTooltip,
    PartialImpressionsTooltip,
    ClicksTooltip,
    FullImpressionsTooltip,
    ViewableImpressionsTooltip,
    CustomerColumn,
    CustomElTableColumn,
    TableEmptySlot
  },
  props: {
    isLoading: Boolean,
    advancedStats: Boolean,
    ads: {
      type: Array,
      required: true
    },
    paginatorInfo: {
      type: Object,
      required: true
    },
    filter: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // State
      isSelectAll: false,
      multipleSelection: [],

      // Consts
      CampaignType,

      // Icons
      nationalIcon,
      superIcon
    };
  },
  computed: {
    selectCount() {
      return this.isSelectAll
        ? this.paginatorInfo.total
        : this.multipleSelection.length;
    }
  },
  methods: {
    onSelectAll() {
      this.isSelectAll = !this.isSelectAll;

      // If we're selecting all or selecting none, the filter is the same - an empty filter
      this.$emit('update:filter', {
        ...(this.filter || {}),
        ad_id: undefined
      });
    },
    onRowClick(row) {
      this.$refs.table.toggleRowSelection(row);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.isSelectAll = false;

      this.$emit('update:filter', {
        ...(this.filter || {}),
        ad_id: this.multipleSelection.map(i => i.ad_id)
      });
    },
    clearSelection() {
      this.$refs.table.clearSelection();
    }
  }
};
</script>
