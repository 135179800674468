<template>
  <a class="cursor-pointer p-4 text-center block max-w-4" :class="styleClass">
    <icon :icon="icon" :class="iconClass" />
  </a>
</template>

<script>
export default {
  props: {
    icon: {
      type: Object,
      required: true
    },
    styleClass: {
      type: [Object, String],
      default: 'text-dark-silver hover:text-gunmetal'
    },
    iconClass: {
      type: String,
      default: 'text-xl'
    }
  }
};
</script>

<style lang="scss">
.max-w-4 {
  max-width: 4em;
}
</style>
