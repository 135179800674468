<script>
import { TableColumn } from 'element-ui';

export default {
  name: 'CustomElTableColumn',

  mixins: [TableColumn],

  created() {
    // For expand or default types, add a <slot name="header" /> slot to this component
    // so we can have easy access to a custom header template for each column
    if (this.type === 'expand' || this.type === 'default') {
      this.columnConfig.renderHeader = (h, data) => {
        if (this.$scopedSlots.header) {
          return this.$scopedSlots.header(data);
        } else if (this.$slots.header) {
          return this.$slots.header;
        } else {
          return data.column.label || '';
        }
      };
    }
  }
};
</script>
