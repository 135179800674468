<template>
  <div
    class="the-page-header my-6 flex justify-between items-center"
    :class="{
      'w-full': isFullWidth
    }"
  >
    <div
      class="flex"
      :class="{
        'w-full': isFullWidth,
        'justify-center text-center': centered,
        'justify-between': !centered
      }"
    >
      <div class="w-full">
        <h1>
          <slot name="title" />
          <slot />
        </h1>
        <div class="mt-1">
          <slot name="subtitle" />
        </div>
      </div>
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    centered: Boolean,
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    isFullWidth: Boolean
  }
};
</script>
