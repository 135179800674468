<template>
  <div id="digital-analytics-website-overview">
    <supplier-digital-analytics-search-tools-query
      :loading.sync="isLoadingSearchTools"
      :type="analyticsType"
      @data="onSearchTools"
    />

    <template v-if="hasReporting">
      <supplier-digital-ad-analytics-query
        :loading.sync="isLoadingAds"
        :type="analyticsType"
        :pager="adsPager"
        @data="onAdsData"
      />

      <supplier-digital-analytics-report-stats-query
        :filter="adsPager.filter"
        :type="analyticsType"
        @data="reportStats = $event.supplier.digitalAnalytics.reportStats"
      />
    </template>

    <transition name="fade" mode="out-in">
      <div
        v-if="isLoadingSearchTools || (hasReporting && !ads)"
        :key="'dashboard-loading'"
      >
        <div class="font-semibold text-3xl text-dark-silver text-center">
          Loading your dashboard...
          <icon :icon="loadingIcon" spin />
        </div>
        <div class="flex justify-center mt-10">
          <div class="max-w-xl flex-grow">
            <loader-line-chart />
          </div>
        </div>
      </div>
      <div
        v-else-if="hasReporting"
        :key="'dashboard-loaded'"
        class="flex flex-row border-top"
      >
        <div class="flex-shrink-0">
          <collapsible-search-group-sidebar
            v-model="searchInput"
            :search-tools="searchTools || []"
            :is-loading="!searchTools"
            :filter="adsPager.filter"
            @search="adsPager = { ...adsPager, ...$event, page: 1 }"
          >
            <template #after>
              <custom-el-switch
                v-model="showEmptyUnitData"
                right
                green-gray
                @input="toggleEmptyUnitFilter"
              />
              Show Empty Ad Unit Data
              <info-tooltip
                content="If disabled, the analytic reports will exclude data when your ad units were not running ads."
              />
            </template>
          </collapsible-search-group-sidebar>
        </div>
        <div
          class="flex flex-1 flex-col p-8 overflow-x-auto min-w-40 border-left bg-silver"
        >
          <digital-analytics-report-stats-dashboard
            :report-stats="reportStats"
            :advanced-stats="isAdvancedStats"
            :filter="adsPager.filter"
          />

          <dashboard-container class="mt-5">
            <template #title>
              Ad Filter
              <info-tooltip
                content="Selecting ads in this table will filter the charts below by the selected ads"
              />
            </template>
            <digital-ad-analytics-mutation>
              <template v-slot="{ isSaving, exportSupplierDigitalAdAnalytics }">
                <filter-toolbar
                  v-model="adsPager.filter"
                  class="items-start"
                  placeholder="Search by ad, customer, etc."
                  :is-exporting="isSaving"
                  @export="onExport(exportSupplierDigitalAdAnalytics)"
                >
                  <div>
                    <el-select v-model="exportOptions.groupBy">
                      <el-option :value="null" label="Export by Date Range" />
                      <el-option value="Date" label="Export by Date" />
                    </el-select>
                  </div>
                </filter-toolbar>
              </template>
            </digital-ad-analytics-mutation>

            <digital-ad-analytics-table
              class="mt-3"
              :advanced-stats="isAdvancedStats"
              :ads="ads || []"
              :paginator-info="adsPaginatorInfo || {}"
              :is-loading="isLoadingAds"
              :filter.sync="chartsFilter"
            />

            <!-- Pagination -->
            <pagination
              v-if="adsPaginatorInfo && adsPaginatorInfo.total > 0"
              class="mt-6"
              :info="adsPaginatorInfo"
              :pager.sync="adsPager"
            />
          </dashboard-container>

          <digital-analytics-charts
            class="mt-5"
            :analytics-type="analyticsType"
            :advanced-stats="isAdvancedStats"
            :filter="{ ...adsPager.filter, ...chartsFilter }"
          />
        </div>
      </div>
      <digital-analytics-empty-state
        v-else-if="digitalStatus"
        :key="'dashboard-empty'"
        :property="primaryDigitalProperty"
        :digital-status="digitalStatus"
      />
    </transition>
  </div>
</template>

<script>
import { arrowsCw as loadingIcon } from '@/vendor/icons';
import { download } from '@/vendor/download';

import CollapsibleSearchGroupSidebar from '@/components/Core/CollapsibleSearchGroupSidebar';
import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import DashboardContainer from '@/components/Supplier/Analytics/DashboardContainer';
import DigitalAdAnalyticsMutation from '@/components/Mutations/Analytics/DigitalAdAnalyticsMutation';
import DigitalAdAnalyticsTable from '@/components/Supplier/Analytics/Digital/DigitalAdAnalyticsTable';
import DigitalAnalyticsCharts from '@/components/Supplier/Analytics/Digital/DigitalAnalyticsCharts';
import DigitalAnalyticsEmptyState from '@/components/Supplier/Analytics/Digital/DigitalAnalyticsEmptyState';
import DigitalAnalyticsReportStatsDashboard from '@/components/Supplier/Analytics/Digital/DigitalAnalyticsReportStatsDashboard';
import FilterToolbar from '@/components/Core/Table/FilterToolbar';
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';
import LoaderLineChart from '@/components/Core/Charts/Loader/LoaderLineChart';
import Pagination from '@/components/Core/Table/Pagination';
import SupplierDigitalAdAnalyticsQuery from '@/components/Queries/Analytics/SupplierDigitalAdAnalyticsQuery';
import SupplierDigitalAnalyticsReportStatsQuery from '@/components/Queries/Analytics/SupplierDigitalAnalyticsReportStatsQuery';
import SupplierDigitalAnalyticsSearchToolsQuery from '@/components/Queries/Analytics/SupplierDigitalAnalyticsSearchToolsQuery';

const EmptyUnitDataFilter = {
  and: {
    ad_id: {
      null: false
    }
  }
};

export default {
  components: {
    CollapsibleSearchGroupSidebar,
    CustomElSwitch,
    DashboardContainer,
    DigitalAdAnalyticsMutation,
    DigitalAdAnalyticsTable,
    DigitalAnalyticsCharts,
    DigitalAnalyticsEmptyState,
    DigitalAnalyticsReportStatsDashboard,
    FilterToolbar,
    InfoTooltip,
    LoaderLineChart,
    Pagination,
    SupplierDigitalAdAnalyticsQuery,
    SupplierDigitalAnalyticsReportStatsQuery,
    SupplierDigitalAnalyticsSearchToolsQuery
  },
  props: {
    analyticsType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // Data
      searchTools: null,
      searchInput: {},
      digitalStatus: null,
      ads: null,
      reportStats: {},

      // State
      adsPager: {
        perPage: 5,
        filter: {
          emptyUnitData: EmptyUnitDataFilter
        }
      },
      exportOptions: {
        groupBy: null
      },
      showEmptyUnitData: false,
      chartsFilter: {},
      adsPaginatorInfo: null,
      isLoadingSearchTools: false,
      isLoadingAds: false,

      // Icons
      loadingIcon
    };
  },
  computed: {
    isAdvancedStats() {
      return this.analyticsType === 'Website';
    },
    primaryDigitalProperty() {
      if (this.searchTools && this.searchTools.length > 0) {
        const propertyOption = this.searchTools
          .find(s => s.label === 'Filter')
          .tools.find(t => t.label === 'Property').data.options[0];

        // Only need the ID to render the script tag installation instructions
        if (propertyOption) {
          return { id: propertyOption.key };
        }
      }

      return null;
    },
    hasReporting() {
      return (
        this.digitalStatus && this.digitalStatus.reporting.property_count > 0
      );
    },
    filterDateRange() {
      return null;
    }
  },
  methods: {
    toggleEmptyUnitFilter() {
      let filter = { ...this.adsPager.filter };

      if (this.showEmptyUnitData) {
        filter.emptyUnitData = undefined;
      } else {
        filter.emptyUnitData = EmptyUnitDataFilter;
      }

      this.adsPager.filter = filter;
    },
    onSearchTools(data) {
      this.searchTools = data.supplier.digitalAnalytics.searchTools;
      this.digitalStatus = data.supplier.digitalAnalytics.digitalStatus;
    },
    onAdsData(data) {
      this.ads = data.supplier.digitalAnalytics.ads.data;
      this.adsPaginatorInfo = data.supplier.digitalAnalytics.ads.paginatorInfo;
    },
    async onExport(exportDigitalAdAnalytics) {
      const data = await exportDigitalAdAnalytics(
        this.adsPager.filter,
        this.exportOptions.groupBy
      );

      download(
        data.mutateAdAnalytics.exportDigitalAdAnalytics,
        'DigitalAdAnalytics.csv'
      );
    }
  }
};
</script>
