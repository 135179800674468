<template>
  <collapsible-sidebar
    :collapse="collapse"
    :notification-count="totalFilterCount"
    show-toggle-icon
    @update:collapse="$emit('update:collapse')"
  >
    <div slot="collapsed">
      <div
        v-for="group in searchGroupsInfo"
        :key="`collapsed-icon-${group.name}`"
      >
        <sidebar-icon
          :icon="group.icon"
          :style-class="{
            [`text-${group.color}`]: filterCount(value[group.name]) > 0,
            'text-dark-silver': filterCount(value[group.name]) === 0
          }"
        />
      </div>
    </div>

    <div class="search-groups-box">
      <template v-if="isLoading">
        <loader-box :height="3.75" class="flex w-full mb-6" />
        <loader-box :height="3.75" class="flex w-full mb-6" />
        <loader-box :height="3.75" class="flex w-full mb-6" />
        <loader-box :height="3.75" class="flex w-full" />
      </template>
      <template v-else>
        <slot name="before" />
        <search-group
          v-for="group in searchTools"
          :key="`search-group-${group.id}`"
          class="w-full"
          :value="model[group.name]"
          :group="group"
          :info="groupInfo(group)"
          :show-unmanaged-group-label="
            showLabels && groupInfo(group).showLabel !== false
          "
          @input="model = { ...model, ...{ [group.name]: $event } }"
        />
        <slot name="after" />
      </template>
    </div>
  </collapsible-sidebar>
</template>

<script>
import SearchGroupSidebar from '@/components/Core/SearchGroupSidebar';
import SearchGroup from '@/components/Core/SearchGroup';
import CollapsibleSidebar from '@/components/Core/CollapsibleSidebar';
import SidebarIcon from '@/components/Core/SidebarIcon';

export default {
  components: {
    SidebarIcon,
    CollapsibleSidebar,
    SearchGroup
  },
  mixins: [SearchGroupSidebar],
  props: {
    collapse: Boolean,
    isLoading: Boolean,
    searchGroupsInfo: {
      type: Array,
      default: () => []
    },
    showLabels: Boolean
  },
  methods: {
    groupInfo(group) {
      return this.searchGroupsInfo.find(gi => gi.name === group.name);
    }
  }
};
</script>
