<template>
  <div class="dashboard-container flex flex-col justify-left">
    <div v-if="hasTitle" class="dashboard-container-title flex-grow">
      <slot name="title">{{ title }}</slot>
    </div>
    <div class="dashboard-container-content">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  },
  computed: {
    hasTitle() {
      return this.$slots.title || this.title;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.dashboard-container {
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  .dashboard-container-title {
    padding: 0.2em 1em;
    color: $color-blue;
    background: lighten($color-blue, 32%);
  }

  .dashboard-container-content {
    padding: 1em;
  }
}
</style>
