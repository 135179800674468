var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"digital-analytics-charts"},[_c('dashboard-container',{attrs:{"title":"Impressions"}},[_c('supplier-ad-analytics-report-query',_vm._b({attrs:{"type":_vm.analyticsType,"filter":_vm.filter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var data = ref.data;
return [_c('report-chart',_vm._b({attrs:{"is-loading":isLoading,"report":data ? data.supplier.digitalAnalytics.report : null}},'report-chart',_vm.impressionsChart,false))]}}])},'supplier-ad-analytics-report-query',_vm.impressionsChart,false))],1),_c('dashboard-container',{staticClass:"mt-5",attrs:{"title":"Clicks"}},[_c('supplier-ad-analytics-report-query',_vm._b({attrs:{"type":_vm.analyticsType,"filter":_vm.filter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var data = ref.data;
return [_c('report-chart',_vm._b({attrs:{"is-loading":isLoading,"report":data ? data.supplier.digitalAnalytics.report : null}},'report-chart',_vm.clicksChart,false))]}}])},'supplier-ad-analytics-report-query',_vm.clicksChart,false))],1),_c('dashboard-container',{staticClass:"mt-5",attrs:{"title":"CTR"}},[_c('supplier-ad-analytics-report-query',_vm._b({attrs:{"type":_vm.analyticsType,"filter":_vm.filter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var data = ref.data;
return [_c('report-chart',_vm._b({attrs:{"is-loading":isLoading,"report":data ? data.supplier.digitalAnalytics.report : null,"field-format":_vm.ReportFormat.PERCENT}},'report-chart',_vm.ctrChart,false))]}}])},'supplier-ad-analytics-report-query',_vm.ctrChart,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }