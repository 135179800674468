<template>
  <div class="summary-item">
    <div
      class="summary-label"
      :class="{ 'text-center': !progress }"
      :style="{ color }"
    >
      <slot>
        {{ label }}
      </slot>
    </div>
    <div class="summary-value" :style="{ color }">
      <tween :value="amount">
        <el-tooltip
          slot-scope="{ value }"
          effect="light"
          :disabled="formatter(value) === shortFormatter(value)"
        >
          <div slot="content" class="text-center">
            {{ formatter(value) }}
            <template v-if="total !== null">/ {{ formatter(total) }}</template>
          </div>
          <div class="flex items-center justify-center">
            <div v-if="progress" class="flex-1 summary-bar">
              <tween :value="percentageAmount">
                <el-progress
                  slot-scope="{ value: tweenedPercentageAmount }"
                  :show-text="false"
                  :stroke-width="14"
                  :color="color"
                  :percentage="
                    Math.min(100, Math.round(tweenedPercentageAmount))
                  "
                />
              </tween>
            </div>
            <div class="p-3">
              {{ shortFormatter(value) }}
            </div>
          </div>
        </el-tooltip>
      </tween>
    </div>
  </div>
</template>

<script>
import {
  currency,
  number,
  percent,
  shortCurrency,
  shortNumber
} from '@/utils/filters';

export default {
  props: {
    amount: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      default: '#788193'
    },
    label: {
      type: String,
      default: null
    },
    total: {
      type: Number, // Required with progress flag
      default: null
    },
    currency: Boolean,
    percent: Boolean,
    progress: Boolean
  },

  computed: {
    percentageAmount() {
      if (this.amount && this.total) {
        return (this.amount / this.total) * 100;
      }
      return 0;
    }
  },

  methods: {
    formatter(value) {
      if (this.currency) {
        return currency(value);
      } else if (this.percent) {
        return percent(value, 1);
      }

      return number(value, 0);
    },

    shortFormatter(value) {
      if (this.percent) {
        return percent(value, 1);
      }

      return this.currency ? shortCurrency(value) : shortNumber(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.summary-label {
  font-size: 0.9em;
}

.summary-bar {
  font-size: 1em;
}

.summary-value {
  font-size: 1.5em;
  line-height: 1em;
  font-weight: 600;
}

.el-progress {
  padding: 0.3em 0;
}
</style>
