<template>
  <span>
    CTR
    <info-tooltip
      content="Click through rate is the number of clicks the ad received divided by the number of impressions."
    />
  </span>
</template>
<script>
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';

export default {
  components: { InfoTooltip }
};
</script>
