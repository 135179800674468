<template>
  <div class="script-code">
    <code ref="code" class="script-code-content">
      <slot />
    </code>
    <div class="action-buttons flex mt-5">
      <div v-if="copy" class="copy-action flex-grow">
        <el-button class="button-green w-full" @click="copyToClipboard">
          {{ isCopied ? 'Success!' : 'Copy' }}
        </el-button>
      </div>
      <slot name="action-buttons" />
    </div>
  </div>
</template>

<script>
import { unescape } from 'lodash';
import { copyToClipboard } from '@/utils/helpers';

export default {
  props: {
    copy: Boolean
  },

  data() {
    return {
      isCopied: false
    };
  },

  methods: {
    copyToClipboard() {
      let code = this.$refs.code.innerHTML;

      // Reformat the code replacing HTML entities
      code = unescape(code);

      copyToClipboard(code);
      this.isCopied = true;

      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    }
  }
};
</script>

<style lang="scss" scoped>
.script-code-content {
  display: block;
  white-space: pre-wrap;
  font-size: 1em;
  padding: 0.8em 1.5em;
}
</style>
