<template>
  <div class="base-chart">
    <canvas v-show="showChart" ref="chart" :height="height"></canvas>
    <div v-if="!showChart" class="base-chart-empty">
      <slot name="empty">
        <div class="text-dark-silver text-base">
          There is no data for the applied filters. Please try changing your
          search terms.
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
import {
  DefaultLineChartOptions,
  formatField,
  ReportFormat
} from '@/constants';
import LineChart from '@/components/Core/Charts/LineChart';

export default {
  extends: LineChart,
  props: {
    isLoading: Boolean,
    height: {
      type: Number,
      default: 100
    },
    fieldFormat: {
      type: String,
      default: null,
      validator(value) {
        return Object.values(ReportFormat).includes(value);
      }
    },
    dimensions: {
      type: Array,
      required: true
    },
    metrics: {
      type: Array,
      required: true
    },
    report: {
      type: [Array, Object],
      default: null
    }
  },
  computed: {
    hasChartData() {
      return this.reportChartData.labels.length > 0;
    },
    records() {
      if (this.report) {
        if (Array.isArray(this.report)) {
          return this.report;
        } else {
          return Object.values(this.report);
        }
      }

      return [];
    },
    maxDataPoint() {
      let max = 0;

      for (let dataset of this.reportChartData.datasets) {
        max = Math.max(max, Math.max(...dataset.data));
      }

      return max;
    },
    reportChartOptions() {
      let plugins;

      if (this.fieldFormat) {
        plugins = {
          tooltip: {
            callbacks: {
              label: context => {
                return (
                  context.dataset.label +
                  ': ' +
                  formatField(context.dataPoint.y, this.fieldFormat)
                );
              }
            }
          }
        };
      }

      return {
        ...DefaultLineChartOptions,
        // Set the max y value to null (auto scale) if there is data, otherwise default to 50
        scales: { y: { max: this.maxDataPoint > 0 ? null : 50 } },
        plugins
      };
    },
    reportChartData() {
      const chartData = {
        labels: [],
        datasets: []
      };

      for (let dimension of this.dimensions) {
        let keyField = dimension.keyField;
        let format = dimension.format;

        if (!keyField.hidden) {
          let key = keyField.value;

          for (let record of this.records) {
            chartData.labels.push(formatField(record[key], format));
          }
        }
      }

      for (let metric of this.metrics) {
        let data = [];

        for (let record of this.records) {
          data.push(+record[metric.name]);
        }

        let dataset = {
          spanGaps: false,
          label: metric.name,
          data,
          ...(metric.chartOptions || {})
        };

        chartData.datasets.push(dataset);
      }

      return chartData;
    }
  },
  watch: {
    /**
     * Whenever the report has changed, lets update the chart!
     */
    report() {
      if (this.reportChartData) {
        if (this.reportChartData.labels.length > 0) {
          this.updateOptions(this.reportChartOptions);
          this.updateData(this.reportChartData);
        }

        this.showChart = this.hasChartData;
      }
    }
  },
  mounted() {
    this.updateOptions(this.reportChartOptions);
  }
};
</script>
