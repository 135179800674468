<script>
import { merge } from 'lodash';
import { ChartColors, ChartLineColors } from '@/constants';

import {
  CategoryScale,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js';

import BaseChart from '@/components/Core/Charts/BaseChart';

const datasetOptionDefaults = {
  pointRadius: 5
};

export default {
  extends: BaseChart,
  props: {
    height: {
      type: Number,
      default: 150
    }
  },
  data() {
    return {
      chartType: 'line',
      toRegister: [
        CategoryScale,
        Legend,
        LinearScale,
        LineController,
        LineElement,
        PointElement,
        Tooltip
      ]
    };
  },
  methods: {
    applyDatasetOptions(index, dataset) {
      dataset.backgroundColor = ChartColors[index % ChartColors.length];
      dataset.borderColor = ChartLineColors[index % ChartLineColors.length];
      dataset = merge(dataset, datasetOptionDefaults);
    }
  }
};
</script>
