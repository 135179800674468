<template>
  <span>
    Viewable Impressions (100%)
    <info-tooltip
      content="Creative for an ad appears on a user's screen and is 100% visible for at least 1 second"
    />
  </span>
</template>
<script>
import InfoTooltip from '@/components/Core/Tooltips/InfoTooltip';

export default {
  components: { InfoTooltip }
};
</script>
