var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"digital-ad-analytics-table"},[_c('div',{staticClass:"flex pb-3 px-4"},[_c('div',{staticClass:"text-dark-silver transition-all"},[(_vm.multipleSelection.length > 0)?[_vm._v(" "+_vm._s(_vm.selectCount)+" ads selected "),_c('a',{staticClass:"text-gray hover:text-dark-silver",on:{"click":_vm.clearSelection}},[_vm._v(" clear ")])]:[_vm._v("Select ads to filter charts below")]],2)]),_c('el-table',{ref:"table",staticClass:"w-full el-table-slim",attrs:{"border":"","data":_vm.isLoading ? [] : _vm.ads},on:{"selection-change":_vm.handleSelectionChange,"select-all":_vm.onSelectAll,"row-click":_vm.onRowClick}},[_c('table-empty-slot',{attrs:{"slot":"empty","is-loading":_vm.isLoading,"loading-rows":_vm.paginatorInfo.perPage,"has-data":!!_vm.ads,"empty-message":"No ads were found matching your filter. Try changing your search terms.","error-message":"There was a problem loading your data. Please try again later."},slot:"empty"}),_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"prop":"ref","label":"Ad ID","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":"This is a national ad from Flytedesk","effect":"light","disabled":row.campaign_type !== _vm.CampaignType.NATIONAL}},[_c('div',[_c('div',[_vm._v(" "+_vm._s(row.ref)+" "),(row.campaign_type === _vm.CampaignType.NATIONAL)?_c('span',{staticClass:"text-blue"},[_c('icon',{attrs:{"icon":_vm.nationalIcon}})],1):_vm._e()]),_c('div',{staticClass:"text-dark-silver text-xs"},[_vm._v(" Order #"+_vm._s(row.order_ref)+" ")])])])]}}])}),_c('el-table-column',{attrs:{"prop":"variant_name","label":"Ad Unit","min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex items-center"},[_c('el-tooltip',{attrs:{"content":"This unit is currently only available to Flytedesk national clients.","effect":"light","disabled":!row.is_super}},[_c('div',[_c('div',[_vm._v(" "+_vm._s(row.variant_name)+" "),(row.is_super)?_c('span',{staticClass:"text-blue"},[_c('icon',{attrs:{"icon":_vm.superIcon}})],1):_vm._e()]),_c('div',{staticClass:"text-dark-silver text-xs"},[_vm._v(_vm._s(row.sku))])])])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"property_name","label":"Property","min-width":"100"}}),_c('el-table-column',{attrs:{"label":"Run Date","align":"left","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm._f("date")(row.start_date)))]),_c('div',[_vm._v("- "+_vm._s(_vm._f("date")(row.end_date)))])]}}])}),_c('el-table-column',{attrs:{"label":"Customer","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('customer-column',{attrs:{"customer":row.customer}})]}}])}),_c('custom-el-table-column',{attrs:{"prop":"requests","width":"130","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('partial-impressions-tooltip')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("number")(row.requests,0))+" ")]}}])}),(_vm.advancedStats)?[_c('custom-el-table-column',{attrs:{"prop":"viewable_impressions","width":"220","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('viewable-impressions-tooltip')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("number")(row.viewable_impressions,0))+" ")]}}],null,false,1862745933)}),_c('custom-el-table-column',{attrs:{"prop":"full_impressions","width":"230","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('full-impressions-tooltip')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("number")(row.full_impressions,0))+" ")]}}],null,false,489228365)})]:_vm._e(),_c('custom-el-table-column',{attrs:{"prop":"clicks","align":"center","width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('clicks-tooltip')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("number")(row.clicks,0))+" ")]}}])}),_c('custom-el-table-column',{attrs:{"prop":"ctr","width":"120","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('partial-ctr-tooltip')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("percent")(row.ctr,1))+" ")]}}])}),(_vm.advancedStats)?[_c('custom-el-table-column',{attrs:{"prop":"viewable_ctr","width":"170","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('viewable-ctr-tooltip')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("percent")(row.viewable_ctr,1))+" ")]}}],null,false,1365109588)}),_c('custom-el-table-column',{attrs:{"prop":"full_ctr","width":"180","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('full-ctr-tooltip')]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("percent")(row.full_ctr,1))+" ")]}}],null,false,2288073428)})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }