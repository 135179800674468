<template>
  <div id="page-digital-analytics" class="page-content">
    <the-page-header>
      <template slot="title">
        Digital Analytics
      </template>
      <template slot="subtitle">
        Review how your ad units performed on your website and e-newsletters
      </template>
    </the-page-header>

    <el-tabs
      :value="$route.name"
      @tab-click="$router.push({ name: $event.name })"
    >
      <el-tab-pane
        key="tab-digital-website"
        name="supplier.analytics.digital.website"
        label="Website"
      />
      <el-tab-pane
        key="tab-digital-email"
        name="supplier.analytics.digital.email"
        label="Email"
      />
    </el-tabs>

    <div class="page-body mt-5">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: { ThePageHeader }
};
</script>
