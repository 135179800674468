<template>
  <div class="digital-analytics-charts">
    <dashboard-container title="Impressions">
      <supplier-ad-analytics-report-query
        v-bind="impressionsChart"
        :type="analyticsType"
        :filter="filter"
      >
        <template v-slot="{ isLoading, data }">
          <report-chart
            :is-loading="isLoading"
            v-bind="impressionsChart"
            :report="data ? data.supplier.digitalAnalytics.report : null"
          />
        </template>
      </supplier-ad-analytics-report-query>
    </dashboard-container>

    <dashboard-container title="Clicks" class="mt-5">
      <supplier-ad-analytics-report-query
        v-bind="clicksChart"
        :type="analyticsType"
        :filter="filter"
      >
        <template v-slot="{ isLoading, data }">
          <report-chart
            :is-loading="isLoading"
            v-bind="clicksChart"
            :report="data ? data.supplier.digitalAnalytics.report : null"
          />
        </template>
      </supplier-ad-analytics-report-query>
    </dashboard-container>

    <dashboard-container title="CTR" class="mt-5">
      <supplier-ad-analytics-report-query
        v-bind="ctrChart"
        :type="analyticsType"
        :filter="filter"
      >
        <template v-slot="{ isLoading, data }">
          <report-chart
            :is-loading="isLoading"
            v-bind="ctrChart"
            :report="data ? data.supplier.digitalAnalytics.report : null"
            :field-format="ReportFormat.PERCENT"
          />
        </template>
      </supplier-ad-analytics-report-query>
    </dashboard-container>
  </div>
</template>
<script>
import { ReportAggregate, ReportFormat } from '@/constants';
import ReportChart from '@/components/Supplier/Analytics/ReportChart';
import SupplierAdAnalyticsReportQuery from '@/components/Queries/Analytics/SupplierAdAnalyticsReportQuery';
import DashboardContainer from '@/components/Supplier/Analytics/DashboardContainer';

export default {
  components: {
    DashboardContainer,
    SupplierAdAnalyticsReportQuery,
    ReportChart
  },
  props: {
    advancedStats: Boolean,
    analyticsType: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      default: null
    }
  },

  data() {
    const impressionsAdvancedStats = this.advancedStats
      ? [
          {
            name: 'Viewable Impressions (50%)',
            field: 'dates.viewable_impressions',
            aggregate: ReportAggregate.SUM
          },
          {
            name: 'Viewable Impressions (100%)',
            field: 'dates.full_impressions',
            aggregate: ReportAggregate.SUM
          }
        ]
      : [];

    const ctrAdvancedStats = this.advancedStats
      ? [
          {
            name: 'Viewable CTR (50%)',
            raw: 'SUM(dates.clicks) / SUM(dates.viewable_impressions)',
            format: ReportFormat.PERCENT
          },
          {
            name: 'Viewable CTR (100%)',
            raw: 'SUM(dates.clicks) / SUM(dates.full_impressions)',
            format: ReportFormat.PERCENT
          }
        ]
      : [];

    return {
      // Consts
      ReportFormat,

      //Charts
      impressionsChart: {
        dimensions: [
          {
            keyField: {
              key: 'dates.date',
              value: 'Date'
            },
            format: ReportFormat.DATE,
            allowMultipleCardinality: true
          }
        ],
        metrics: [
          {
            name: 'Impressions',
            field: 'dates.requests',
            aggregate: ReportAggregate.SUM,
            format: ReportFormat.INTEGER
          },
          ...impressionsAdvancedStats
        ],
        sort: [
          {
            column: 'dates.date',
            order: 'ASC'
          }
        ]
      },
      clicksChart: {
        dimensions: [
          {
            keyField: {
              key: 'dates.date',
              value: 'Date'
            },
            format: ReportFormat.DATE,
            allowMultipleCardinality: true
          }
        ],
        metrics: [
          {
            name: 'Clicks',
            field: 'dates.clicks',
            aggregate: ReportAggregate.SUM,
            format: ReportFormat.INTEGER
          }
        ],
        sort: [
          {
            column: 'dates.date',
            order: 'ASC'
          }
        ]
      },
      ctrChart: {
        dimensions: [
          {
            keyField: {
              key: 'dates.date',
              value: 'Date'
            },
            format: ReportFormat.DATE,
            allowMultipleCardinality: true
          }
        ],
        metrics: [
          {
            name: 'CTR',
            raw: 'SUM(dates.clicks) / SUM(dates.requests)',
            format: ReportFormat.PERCENT
          },
          ...ctrAdvancedStats
        ],
        sort: [
          {
            column: 'dates.date',
            order: 'ASC'
          }
        ]
      }
    };
  }
};
</script>
