<template>
  <div class="buyer-column" @click.stop.prevent>
    <template v-if="buyer">
      <el-popover v-if="!isNational">
        <a slot="reference" class="buyer inline-block">
          <div class="buyer-company">{{ buyerDisplayName }}</div>
        </a>

        <div class="buyer-popover">
          <h3 class="buyer-company">
            {{ buyer.buyer_company }}
          </h3>
          <div class="buyer-contact mt-3">
            <template v-if="campaign">
              <contact-info
                v-if="campaign.primaryContact"
                :contact="campaign.primaryContact"
                title="Contact"
              />
            </template>

            <contact-info
              v-else-if="buyer.primaryContact"
              :contact="buyer.primaryContact"
              title="Contact"
            />

            <template v-if="campaign">
              <div v-if="campaign.billingContact" class="mt-3">
                <contact-info
                  :contact="campaign.billingContact"
                  title="Billing"
                />
              </div>
            </template>
          </div>
          <div v-if="buyer.primaryAddress" class="buyer-address mt-3">
            <h4>Address:</h4>
            <div class="address">
              <div>{{ buyer.primaryAddress.address }}</div>
              <div v-if="buyer.primaryAddress.address2">
                {{ buyer.primaryAddress.address2 }}
              </div>
              <div
                v-if="buyer.primaryAddress.city && buyer.primaryAddress.state"
              >
                {{ buyer.primaryAddress.city }},
                {{ buyer.primaryAddress.state }}
                {{ buyer.primaryAddress.zip_code }}
              </div>
            </div>
          </div>
          <div v-if="customer.billing_preference" class="mt-3">
            <h4>Payment Preference:</h4>
            <div>{{ BillingPreferenceLabel[customer.billing_preference] }}</div>
          </div>
          <div v-if="customer.terms" class="mt-3">
            <h4>Invoice Terms</h4>
            <div>{{ customer.terms.text }}</div>
          </div>
        </div>
      </el-popover>
      <el-popover v-else trigger="hover" placement="right" class="flex">
        <div class="text-left w-64">
          <div class="font-bold pb-2">
            Flytedesk National Campaign
          </div>
          This order was placed by a flytedesk national buyer.
          <a
            href="https://intercom.help/flytedesk/en/articles/2644946-how-does-flytedesk-sell-my-inventory-to-national-brands"
            target="_blank"
            >Learn more about national ads.</a
          >
        </div>
        <div slot="reference" class="flex items-center">
          <span class="text-blue">{{ buyerDisplayName }}</span>
          <icon :icon="flyteBadgeIcon" class="text-lg text-blue ml-1" />
        </div>
      </el-popover>
    </template>
    <div v-if="campaign">
      {{ campaign.name }}
    </div>
  </div>
</template>

<script>
import ContactInfo from '@/components/Core/ContactInfo';
import { BillingPreferenceLabel, CampaignType } from '@/constants';
import { flyteBadge as flyteBadgeIcon } from '@/vendor/icons';

export default {
  components: {
    ContactInfo
  },
  props: {
    customer: {
      type: Object,
      default: null
    },
    campaign: { type: Object, default: null }
  },
  data() {
    return {
      BillingPreferenceLabel,

      // Icons
      flyteBadgeIcon
    };
  },
  computed: {
    buyer() {
      return this.customer && this.customer.buyer;
    },
    buyerDisplayName() {
      return this.buyer
        ? `${this.buyer.buyer_company}` +
            (this.buyer.id ? `(${this.buyer.id})` : '')
        : '';
    },
    isNational() {
      return this.campaign && this.campaign.type === CampaignType.NATIONAL;
    }
  }
};
</script>
