<!-- eslint-disable -->
<template>
  <div class="digital-script-tag">
    <!-- @formatter:off -->
    <script-code ref="code" :copy="copy">
      <template #default>
        <slot>
          &lt;!-- Flytedesk Digital --&gt;

          &lt;script type="text/javascript"&gt;
          (function (w, d, s, p) { var f = d.getElementsByTagName(s)[0], j =
          d.createElement(s); j.id = 'flytedigital'; j.async = true; j.src =
          '{{ scriptUrl }}#' + p;
          f.parentNode.insertBefore(j, f); })(window, document, 'script', '{{ id }}');
          &lt;/script&gt;

          &lt;!-- End Flytedesk Digital --&gt;
        </slot>
      </template>

      <template #action-buttons>
        <div v-if="preview && $can('preview_digital_script_tag')" class="preview-action ml-3 flex-grow">
          <a target="_blank" :href="previewUrl">
            <el-button class="button-blue w-full">
              Preview
            </el-button>
          </a>
        </div>
      </template>
    </script-code>
    <!-- @formatter:on -->
  </div>
</template>

<script>
import ScriptCode from '@/components/Supplier/Mediakit/Common/ScriptCode';

export default {
  components: { ScriptCode },
  props: {
    id: {
      type: String,
      required: true
    },
    copy: Boolean,
    preview: Boolean
  },

  computed: {
    scriptUrl() {
      return process.env.VUE_APP_SCRIPT_TAG_SRC;
    },
    previewUrl() {
      return (
        this.$router.resolve({
          name: 'digital.preview',
          params: { property_id: this.id }
        }).href + '?fddebug'
      );
    }
  }
};
</script>
