<script>
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { date as dateFormat } from '@/utils/filters';

import { defaultLineChartData, DefaultLineChartOptions } from '@/constants';
import LineChart from '@/components/Core/Charts/LineChart';

const chartData = cloneDeep(defaultLineChartData);

export default {
  extends: LineChart,
  props: {
    points: {
      type: Number,
      default: 12
    },
    max: {
      type: Number,
      default: 50
    },
    min: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 1000
    }
  },
  mounted() {
    this.updateOptions(DefaultLineChartOptions);
    this.updateOptions({
      scales: {
        y: {
          max: this.max,
          min: this.min
        }
      },
      animation: {
        tension: {
          duration: this.duration
        }
      }
    });

    this.defineLabels();
    this.animateLoading();
  },
  methods: {
    defineLabels() {
      const labels = [];
      let now = moment().startOf('month');

      for (let i = 0; i++ < this.points; ) {
        labels.push(dateFormat(now));
        now.add(-1, 'month');
      }

      chartData.labels = labels.reverse();
    },
    animateLoading() {
      this.randomize();
      if (this._isMounted && !this._isDestroyed) {
        setTimeout(this.animateLoading, this.duration);
      }
    },
    randomize() {
      for (let dataset of chartData.datasets) {
        for (let i = 0; i < this.points; i++) {
          let offset = Math.random() * this.max;

          // Adjust offset to ease magnitude of change
          if (dataset.data[i]) {
            offset = offset / 3 - this.max / 6;
          }

          dataset.data[i] = Math.min(
            this.max,
            Math.max(this.min, offset + (dataset.data[i] || 0))
          );
        }
      }

      this.updateData(chartData);
    }
  }
};
</script>
