<template>
  <section
    class="collapsible-sidebar transition-width-.3 top-0 sticky"
    :class="isCollapsed ? 'w-16 overflow-hidden' : 'w-panel overflow-y-auto'"
  >
    <div class="collapsible-sidebar-header flex justify-between items-center">
      <div
        class="collapsible-sidebar-open p-4 cursor-pointer"
        @click="toggle(!isCollapsed)"
      >
        <slot name="open">
          <notification-icon
            circle-color="baby-blue"
            :is-active="true"
            :icon="slidersIcon"
            :count="notificationCount"
          />
        </slot>
      </div>
      <div class="collapsible-sidebar-close" @click="toggle(true)">
        <slot name="close">
          <transition name="fade" mode="out-in">
            <sidebar-icon
              v-if="!isCollapsed"
              :icon="cancelIcon"
              icon-class="text-md"
            />
          </transition>
        </slot>
      </div>
    </div>

    <div class="collapsible-sidebar-body">
      <transition name="fade" mode="out-in" :duration="300">
        <div
          v-if="isCollapsed"
          :key="'collapsed-sidebar'"
          class="collapsed-sidebar text-left"
          @click="toggle(false)"
        >
          <slot name="collapsed" />

          <sidebar-icon v-if="showToggleIcon" :icon="toggleIcon" />
        </div>
        <div v-else :key="'open-sidebar'" class="collapsible-sidebar-content">
          <div class="pr-6">
            <slot />
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import {
  angleDoubleRight as toggleIcon,
  cancel as cancelIcon,
  sliders as slidersIcon
} from '@/vendor/icons';
import SidebarIcon from '@/components/Core/SidebarIcon';

export default {
  components: { SidebarIcon },
  props: {
    collapse: Boolean,
    showToggleIcon: Boolean,
    notificationCount: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isCollapsed: false,

      toggleIcon,
      cancelIcon,
      slidersIcon
    };
  },
  watch: {
    collapse() {
      this.isCollapsed = this.collapse;
    }
  },
  methods: {
    toggle(state) {
      this.isCollapsed = state;
      this.$emit('update:collapse', this.isCollapsed);
    }
  }
};
</script>

<style lang="scss">
.collapsible-sidebar {
  position: sticky;
  top: 0;
  max-height: calc(100vh - 60px);
}
</style>
