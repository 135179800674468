<template>
  <div class="switch el-switch" :class="switchClass">
    <div
      v-show="!checked"
      class="el-switch__label el-switch__label--left"
      @click="switchValue"
    >
      <div v-if="disabled" class="inline-block pad-right-xs">
        <tooltip-icon icon-class="text-dark-silver" :is-disabled="disabled">
          <div
            v-if="disabledMessage"
            slot="disabled"
            class="text-left max-w-sm"
          >
            {{ disabledMessage }}
          </div>
        </tooltip-icon>
      </div>
      <slot name="inactive" />
    </div>
    <el-switch
      ref="switch"
      :class="switchClass"
      v-bind="$props"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
    <div
      v-show="checked"
      class="el-switch__label el-switch__label--right"
      @click="switchValue"
    >
      <div v-if="disabled" class="inline-block pad-right-xs">
        <tooltip-icon icon-class="text-dark-silver" :is-disabled="disabled">
          <div
            v-if="disabledMessage"
            slot="disabled"
            class="text-left max-w-sm"
          >
            {{ disabledMessage }}
          </div>
        </tooltip-icon>
      </div>
      <slot name="active" />
    </div>
  </div>
</template>

<script>
import { Switch } from 'element-ui';
import TooltipIcon from '@/components/Core/TooltipIcon';

export default {
  components: {
    TooltipIcon
  },
  props: {
    ...Switch.props,
    left: Boolean,
    disabledMessage: {
      type: [String, null],
      default: null
    },
    greenRed: Boolean,
    greenGray: Boolean
  },

  computed: {
    ...Switch.computed,

    switchClass() {
      let classes = [this.left ? 'has-label-left' : 'has-label-right'];

      if (this.greenRed) {
        classes.push('toggle-green-red');
      } else if (this.greenGray) {
        classes.push('toggle-green-gray');
      }

      if (this.checked) {
        classes.push('is-checked');
      }

      return classes;
    }
  },

  mounted() {
    // We need to map $refs from the child Switch component as some of the Switch.methods use the $refs
    this.$refs = { ...this.$refs, ...this.$refs.switch.$refs };
  },

  methods: {
    ...Switch.methods
  }
};
</script>
